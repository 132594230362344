<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="400">
    <v-card>
        <v-toolbar class="mid-font" color="primary" dense dark >
            <v-icon left >mdi-account</v-icon><span class="va-underline">{{state=='new'?'New':'Edit'}} User</span>
            <v-spacer/>
            <v-icon  @click="emitToParent('close')">mdi-close</v-icon>
          </v-toolbar>
        <v-card-text>
             <v-form
                ref="form"
                class="mt-4"
                autocomplete="off"
                v-model="valid"
                lazy-validation
              >
              <v-row>
                <v-col cols="12">
                  
               <v-text-field
                 v-model="user.fullname"
                  label="Full Name *"
                  outlined
                  dense
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                 outlined
                  dense
                 v-model="user.username"
                  label="Username *"
                  :rules="textRules"
                required
                ></v-text-field>

                <v-text-field
                  v-model="user.password"
                   type="password"
                  v-if="state=='new'" 
                  label="Password *"
                  :rules="textRules"
                required
                 outlined
                  dense
                ></v-text-field>
                 <v-autocomplete
                  v-model="user.role"
                  item-text="name"   
                   :rules="textRules"
                required
                  :items="ROLES"
                  outlined
                    dense
                  label="Account Type"
                  ></v-autocomplete>
                <!-- <v-autocomplete
                v-if="user.role == 'Agent'"
                  v-model="elite"
                  item-text="fullname"
                   return-object
                   :rules="textRules"
                required
                  :items="ELITES"
                  outlined
                    dense
                  label="Select Elite Upline"
                  ></v-autocomplete> 
                    <v-autocomplete
                v-if="user.role == 'Player'"
                  v-model="agent"
                  item-text="fullname"
                  return-object
                   :rules="textRules"
                required
                  :items="AGENTS"
                  outlined
                    dense
                  label="Select Agent Upline"
                  ></v-autocomplete>  -->
              <!-- <v-text-field
                  v-model="user.contact_no"
                   outlined
                  dense
                  label="Contact No. "
                ></v-text-field>
                <v-text-field
                  v-model="user.email"
                   outlined
                  dense
                  label="Email"
                ></v-text-field>
                 <v-text-field
                  v-model="user.commission"
                   outlined
                   type="number"
                  dense
                  label="Commission"
                ></v-text-field> -->
                  
                  
                  <!-- <v-autocomplete
                  v-model="user.status"
                   :rules="textRules"
                required
                  :items="['Active','Deactivate']"
                  outlined
                    dense
                  label="Status"
                  ></v-autocomplete>      -->
                 <v-text-field
                      v-model="newpassword"
                      type="password"
                      outlined
                    dense
                      name="input-10-1"
                      label="Change Password"
                      append-outer-icon="mdi-content-save-move"
                      @click:append-outer="ChangePassword()"
                      v-if="show1==true"
                    ></v-text-field>
                   <v-btn   v-if="state!='new'" @click="show1=true" color="darken-1" text small><v-icon left small>mdi-key-variant</v-icon> Change Password</v-btn>
                </v-col>
               
              </v-row>
             </v-form>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning" small rounded text @click="emitToParent('close')">CLOSE</v-btn>
            <v-spacer></v-spacer>
            <v-btn  small color="success"  depressed  @click="validate()">{{BTN_TEXT}}</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    },
    user_id: {
        type: Number,
        default:null
    },
    users: {
        type: Array,
        default: ()=>[]
    },
    state: {
        type: String,
        default: "new"
    }
  },
  data: () => ({
      valid: true,
      newpassword:'',
      user:{},
      agent:{},
      elite:{},
      show1: false,
      textRules: [
        v => !!v || 'Field is required'
      ],
  }),
  computed: {
      BTN_TEXT() {
          if(this.state == 'new')
            return "ADD"
         else 
            return "SAVE"
      },
      ROLES() {
        return ['Administrator', 'Operation']
      },
      ELITES() {
        var items = this.users.reduce((res, item) =>{
          if("Elite" == item.role) {
            res.push(item)
          }
          return res
        }, [])
        return items
      },
      AGENTS() {
        var items = this.users.reduce((res, item) =>{
          if("Agent" == item.role) {
            res.push(item)
          }
          return res
        }, [])
        return items
      }
  },
  watch: {
    show (v) {
      if (!v) {
        this.newpassword = ''
        this.show1=false
      } else {
        this.user.password =''
        this.getAccount()
      }
    }
  },
  methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.emitToParent(this.state)
          this.show1 = false
        }
      },
      getAccount(){
          this.$http.post("user/get", {id: this.user_id}).then(response => {
            response.data.status? this.user = response.data.account:this.user={} 
        }).catch(e => {
          console.log(e)
        })
      },
       ChangePassword: function() {
       var param = {}
       param.id = this.user.id
       param.password = this.newpassword
        this.$http.post("user/change_password", param).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message) : this.VA_ALERT('error', response.data.message) 
            
        }).catch(e => {
          console.log(e)
        })
     },
    emitToParent (action) {
      if(this.show1) {
        this.user.password = this.newpassword
      }
      this.user.commission = this.$IsNum(this.user.commission)
      this.user.status= 'Active'
      this.$emit('DialogEvent', {action: action, user:this.user})
    }

  }
}
</script>
