/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/dashboard',
      name: 'Dashboard',
      view: 'Dashboard'
    },
    {
      path: '/census',
      name: 'Census',
      view: 'Census'
    }, 
    {
      path: '/finder',
      name: 'Data Finder',
      view: 'Finder'
    },
    {
      path: '/data-sheet/:id',
      name: 'Data Sheet',
      view: 'DataSheet'
    },
    {
      path: '/scanner',
      name: 'Scanner',
      view: 'Scanner'
    },
    {
      path: '/runner',
      name: 'Runner',
      view: 'Runner'
    },
    {
      path: '/login',
      name: 'Login',
      view: 'Login'
    },
    {
      path: '/users',
      name: 'Account Management',
      view: 'accounts/Users'
    },
    {
      path: '/voters',
      name: 'Voters Management',
      view: 'accounts/Voters'
    },
    {
      path: '/vouchers',
      name: 'Voucher Management',
      view: 'accounts/Voucher'
    },
    {
      path: '/events',
      name: 'Events',
      view: 'accounts/Events'
    },
    {
      path: '/per-event',
      name: 'Per Event',
      view: 'accounts/PerEvent'
    },
    {
      path: '/per-user',
      name: 'Per Distributor',
      view: 'accounts/PerUser'
    },
  ]
  